<div class="wrap_fluid">
    <div class="wrap_content">
        <div class="wrap_container">
            <div class="auth_middle">
                <div class="auth_heading">
                    <div class="brand_icon">
                        <img src="../../assets/img/logo.png">
                    </div>
                    <h1>IASGCON2025</h1>
                    <h3>Abstract Submission</h3>
                </div>

                <div class="auth_form">
                    <!-- loginId -->
                    <div *ngIf="isUserAlredyExists=== false">
                        <form [formGroup]="validateEmailform" (ngSubmit)="onValidateEmail(validateEmailform.value)">
                            <div class="auth_form_head">
                                <h2>Get Started.</h2>
                                <!-- <p>Login to continue.</p> -->
                            </div>
                            <div class="auth_form_body">
                                <div class="form-outline">
                                    <div class="form-floating">
                                        <input type="email" style="text-transform:lowercase" #textEmail class="form-control" id="floatingInputEmail" placeholder="Enter your email id" formControlName="Email">
                                        <label for="floatingInputEmail">Enter your email id</label>
                                    </div>
                                    <div class="forms_error_info"
                                        *ngIf="validateEmailform.controls['Email'].invalid && validateEmailform.controls['Email'].dirty">

                                        <span class="error"
                                            *ngIf="validateEmailform.controls['Email'].errors.required">Email
                                            required</span>
                                        <span class="error"
                                            *ngIf="validateEmailform.controls['Email'].errors.pattern && !validateEmailform.controls['Email'].errors.maxlength && !validateEmailform.controls['Email'].errors.minlength">Invalid
                                            email id</span>
                                    </div>

                                </div>
                                <a (click)="onValidateEmail(validateEmailform.value)"
                                    class="btn app_btn btn_secondary">Login / Register</a>
                            </div>
                        </form>
                    </div>
                    <!-- loginId -->

                    <!--password OR OTP-->
                    <div *ngIf="isUserAlredyExists === true">
                        <form [formGroup]="validatePasswordform"
                            (ngSubmit)="onValidatePassword(validatePasswordform.value)">
                            <div class="auth_form_head">
                                <h2>You are already registered!</h2>
                                <p></p>
                            </div>
                            <div class="auth_form_body">
                                <div class="form-outline">
                                    <div class="form-floating">
                                        <input  class="form-control" id="floatingInputPassword" type="password" placeholder="Enter your password" formControlName="Password"
                                        formControlName="Password" autocomplete="on"  #textPassword>
                                        <label for="floatingInputPassword">Enter your password</label>
                                    </div>
                                    <!-- <input type="password" id="form1" class="form-control" formControlName="Password" />
                                    <label class="form-label" for="form1">Enter your password</label> -->
                                    <div class="forms_error_info"
                                        *ngIf="validatePasswordform.controls['Password'].invalid && validatePasswordform.controls['Password'].dirty">
                                        <span class="error"
                                            *ngIf="validatePasswordform.controls['Password'].errors.required">Password
                                            required</span>
                                        <span class="error"
                                            *ngIf="validatePasswordform.controls['Password'].errors.maxlength">Password
                                            length exceeds </span>
                                    </div>
                                </div>
                                <a (click)="onValidatePassword(validatePasswordform.value)"
                                    class="btn app_btn btn_secondary">Continue</a>

                                <div class="or">
                                    <span>Or</span>
                                </div>

                                <a (click)="loginUsingOtp()" class="btn app_btn btn-outline-dark"
                                    data-mdb-ripple-color="dark">Login Using OTP</a>
                                    <div class="mt-4"><a style="color: #d90845;" href="javascript:void(0)" (click)="onBack()">Back</a></div>  
                            </div>
                        </form>
                    </div>
                    <!--password OR OTP -->


                    <!-- OTP Verification -->
                    <div *ngIf="isShowOtpVerifyScreen">
                        <div class="auth_form_head">
                            <h2>Enter your 4 digit OTP.</h2>
                            <p></p>
                        </div>
                        <div class="auth_form_body">
                            <form [formGroup]="oneTimePasswordFormGroup"
                            (ngSubmit)="onSubmitOtpFormGroup(oneTimePasswordFormGroup.value)">
                            <div class="form-outline">
                              <div class="opt_grid box_4">
                                <input mdbInput id="otp0" (input)="otpInputs($event,0)" (paste)="otpPaste($event,0)"
                                  (keyup)="otpOneCall($event,0)" placeholder="*" type="tel" pattern="\d" inputmode="numeric"
                                  formControlName="input1" class="form-control otp" autofocus maxlength="1"
                                  oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0,1)" />
                              
                                <input mdbInput id="otp1" type="tel" (input)="otpInputs($event,1)" (paste)="otpPaste($event,1)"
                                  (keyup)="otpOneCall($event,1)" formControlName="input2" class="form-control otp"
                                  placeholder="*" pattern="\d" inputmode="numeric" maxlength="1"
                                  oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0,1)" />
                              
                                <input mdbInput id="otp2" type="tel" (input)="otpInputs($event,2)" placeholder="*"
                                  (paste)="otpPaste($event,2)" (keyup)="otpOneCall($event,2)" formControlName="input3"
                                  class="form-control otp" pattern="\d" inputmode="numeric" maxlength="1"
                                  oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0,1)" />
                              
                                <input mdbInput id="otp3" type="tel" (input)="otpInputs($event,3)" placeholder="*"
                                  (paste)="otpPaste($event,3)" (keyup)="otpOneCall($event,3)" formControlName="input4"
                                  class="form-control otp" pattern="\d" inputmode="numeric" maxlength="1"
                                  oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0,1)" />
                              </div>
                              
                              
                            </div>
                            <div class="forms_error_info" *ngIf="showInvalidOtp">
                              <i class="fa fa-close mr-1"></i>
                              <span>Please enter valid OTP</span>
                            </div>
                            <button  type="submit" id="otpSubmitBtn"
                              class="btn app_btn btn_secondary">Continue</button>
                              <div class="mt-4"><a style="color: #d90845;" href="javascript:void(0)" (click)="onBackPassowrdEnter()">Back</a></div>  
                          </form>
                        </div>
                    </div>
                    <!-- OTP Verification -->
                </div>
                <div class="auth_btn_block">
                    <div class="stack_btn_group">
                        <a href="javascript:void(0)" class="link_btn" (click)="openAboutMoodal()">About IASGCON 2025</a>
                        <app-guidelines></app-guidelines>
                        <!-- <a href="javascript:void(0)" class="link_btn" data-mdb-toggle="modal" data-mdb-target="#guidlineModal">View Guideline</a> -->
                        <a href="javascript:void(0)" class="link_btn" (click)="openSecretariatModal()">Secretariat</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- App Footer -->
    <footer class="app_footer">
        <div class="footer_flex">
            <p>©IASGCON2025 - All rights reserved.</p>
            <!-- <ul>
                    <li><a  href="https://www.isshp2023.com/privacy-policy.html">Privacy Policy</a></li>
                    <li><a href="javascript:void(0)">Terms &amp; Conditions</a></li>
                </ul> -->
        </div>
    </footer>
    <!--/. App Footer -->
</div>


<!--About Modal -->
<div class="modal fade" id="aboutModal" bsModal #aboutModal="bs-modal" tabindex="-1"
    aria-labelledby="saveDraftModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="saveDraftModalLabel">About</h5>
                <button type="button" class="btn-close" (click)="aboutModal.hide()" data-mdb-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="static">
                    <div class="static_block">
                        <h6>IASGCON 2025 - GI Surgery in the Digital Era</h6>

                        <p>35th National Conference of the Indian Association of Surgical Gastroenterology will be held from 09th - 12th October 2025 at ITC Grand Chola, Chennai. This conference will be of interest to all surgical gastroenterologist, general surgeons, upper GI, hepatopancreaticobiliary, colorectal, bariatric and liver transplant surgeons.</p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn app_btn btn_close" data-mdb-dismiss="modal"
                    (click)="aboutModal.hide()">Close</button>
            </div>
        </div>
    </div>
</div>
<!--/. About Modal -->

<!--secretariat Modal -->
<div class="modal fade" id="secretariatModal" bsModal #secretariatModal="bs-modal" tabindex="-1"
    aria-labelledby="saveDraftModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="saveDraftModalLabel">Secretariat</h5>
                <button type="button" class="btn-close" (click)="secretariatModal.hide()" data-mdb-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="static">
                    <div class="static_block">
                        <h6>Indian Association of Surgical Gastroenterology</h6>

                        <p><strong><em>For queries regarding academic matters, email to:</em></strong></p>
                        

                        <p><a href="mailto:iasgscientificommittee@gmail.com">iasgscientificommittee@gmail.com</a></p>

                        <p><strong><em>For technical support, email to:</em></strong></p>
                        
                        <p><a href="mailto:aruna@tvasan.in">aruna@tvasan.in</a></p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn app_btn btn_close" data-mdb-dismiss="modal"
                    (click)="secretariatModal.hide()">Close</button>
            </div>
        </div>
    </div>
</div>
<!--/. secretariat Modal -->

<script src="https://code.jquery.com/jquery-3.6.1.min.js"></script><!-- jQuery -->
<script src="https://cdnjs.cloudflare.com/ajax/libs/mdb-ui-kit/6.0.1/mdb.min.js" type="text/javascript"></script>