<div
  class="popover"
  [@fade]="animationState"
  (@fade.done)="onAnimationEnd($event)"
  [@.disabled]="!animation"
>
  <h3 *ngIf="title" class="popover-header">
    {{ title }}
  </h3>
  <div *ngIf="isContentTemplate" class="popover-body">
    <ng-container [ngTemplateOutlet]="$any(content)"></ng-container>
  </div>
  <div *ngIf="!isContentTemplate" class="popover-body">
    {{ content }}
  </div>
</div>
