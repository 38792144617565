import {
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import {
  EventEmitter, Injectable } from '@angular/core';
import {
  Observable,
  throwError as observableThrowError,
} from 'rxjs';
import { Location } from '@angular/common';
import {
  catchError, map } from 'rxjs/operators';
import { LoaderService } from './loader/loader.service';
import { MessageService } from './message/message.service';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  public options: {};
  public blobOutput = false;
  public htmlOutput = false;
  public forceLogout: EventEmitter<boolean> = new EventEmitter();
  constructor(private http: HttpClient, private loaderService: LoaderService, private messagingService: MessageService,private location:Location) {
    }

  /**
   * GET method implementation in common rest service
   * @param url - get url
   * @param param - url params
   * @param [disableLoader] - enable/disable loader
   * @param [blobOutput] - enable/disable output stringify
   * @param [html] - add headers for html
   * @returns `Observable stream` of the api response
   */
  public get(url: string, param: {}, disableLoader = false, blobOutput?: boolean, html?: boolean): Observable<{}> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Pragma', 'no-cache');
    const token = localStorage.getItem('token');
    if(token){
      headers = headers.append('Authorization', `Bearer ${token}`);
    }
    this.blobOutput = blobOutput;
    this.htmlOutput = html;
    let responseType;
    if (!disableLoader) {
      this.showLoader(true);
    }
    let queryParams = new HttpParams();
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        queryParams = queryParams.append(key, param[key]);
      }
    }
    if (this.blobOutput) {
      headers = headers.set('Accept', 'application/octet-stream');
      responseType = 'blob';
    } else if (this.htmlOutput) {
      headers = headers.delete('Accept');
      responseType = 'text';
    } else {
      headers = headers.delete('Accept');
      responseType = 'json';
    }
    this.options = {
      headers,
      params: queryParams,
      responseType,
      withCredentials: true,
      observe: 'response'
    };
    return this.http
      .get(url, this.options).pipe(
        map((data: Response) =>
          this.handleResponse(data, disableLoader)),
        catchError((error) => {
          this.handleError(error, disableLoader);
          return observableThrowError(error);
        }) );
  }

  /**
   * POST method implementation in common rest service
   * @param url - get url
   * @param param - url params
   * @param [disableLoader] - enable/disable loader
   * @param [upload] - add headers for form-data
   * @param [blobOutput] - enable/disable output stringify
   * @returns `Observable stream` of the api response
   */
  public post(url: string, param: {}, disableLoader = false, upload?: boolean, blobOutput?: boolean, html?: boolean): Observable<{}> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Pragma', 'no-cache');
    const token = localStorage.getItem('token');
    if(token){
      headers = headers.append('Authorization', `Bearer ${token}`);
    }
    let responseType = 'json';
    this.blobOutput = blobOutput;
    this.htmlOutput = html;
    let body = param;
    if (upload) {
      // Other header params are not accepted in upload (server)
      headers = new HttpHeaders({
        enctype: 'multipart/form-data',
        Authorization:  `Bearer ${token}`
      });
    } else {
      body = JSON.stringify(param);
    }

    if (!disableLoader) {
      this.showLoader(true);
    }
    if (this.blobOutput) {
      responseType = 'blob';
    }
    this.options = {
      headers,
      responseType,
      withCredentials: true,
      observe: 'response'
    };

    return this.http.post(url, body, this.options).pipe(
      map((data: Response) =>
        this.handleResponse(data, disableLoader)),
      catchError((error) => {
        this.handleError(error, disableLoader);
        return observableThrowError(error);
      }) );
  }
  /**
   * PUT method implementation in common rest service
   * @param url - get url
   * @param param - url params
   * @param [disableLoader] - enable/disable loader
   * @param [upload] - add headers for form-data
   * @returns `Observable stream` of the api response
   */
  public put(url: string, param: {},  disableLoader = false , upload ?: boolean, html?: boolean): Observable < {} > {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Pragma', 'no-cache');
    const token = localStorage.getItem('token');
    if(token){
      headers = headers.append('Authorization', `Bearer ${token}`);
    }
    this.htmlOutput = html;
    let body = param;
    if (upload) {
      // Other header params are not accepted in upload (server)
      headers = new HttpHeaders({
        enctype: 'multipart/form-data',
        Authorization:  `Bearer ${token}`
      });
    } else {
      // headers = new HttpHeaders({
      //   'Content-Type': 'application/json'
      // });
      body = JSON.stringify(param);
    }

    if (!disableLoader) {
      this.showLoader(true);
    }

    if (html) {
      body = param;
    } else {
      body = JSON.stringify(param);
    }

    this.options = {
      headers,
      withCredentials: true,
      observe: 'response'
    };

    return this.http.put(url, body, this.options).pipe(
      map((data: Response) =>
        this.handleResponse(data, disableLoader)),
      catchError((error) => {
        this.handleError(error, disableLoader);
        return observableThrowError(error);
      }) );
  }
  /**
   * DELETE method implementation in common rest service
   * @param url - get url
   * @param param - url params
   * @param [disableLoader] - enable/disable loader
   * @returns  `Observable stream` of the api response
   */
  public delete(url: string, param: {},  disableLoader = false ): Observable < {} > {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Pragma', 'no-cache');
    const token = localStorage.getItem('token');
    if(token){
      headers = headers.append('Authorization', `Bearer ${token}`);
    }
    this.htmlOutput = false;
    if (!disableLoader) {
      this.showLoader(true);
    }

    let queryParams = new HttpParams();
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        queryParams = queryParams.append(key, param[key]);
      }
    }
    this.options = {
      headers,
      params: queryParams,
      withCredentials: true,
      observe: 'response'
    };

    return this.http
      .delete(url, this.options).pipe(
        map((data: Response) =>
          this.handleResponse(data, disableLoader)),
        catchError((error) => {
          this.handleError(error, disableLoader);
          return observableThrowError(error);
        }) );
  }
  /**
   * POST method implementation in common rest service only for EMBRIDGE calls
   * @param url - get url
   * @param param - url params
   * @param [disableLoader] - enable/disable loader
   * @param [upload] - add headers for form-data
   * @param [blobOutput] - enable/disable output stringify
   * @returns `Observable stream` of the api response
   */
  public emBridgePost(url: string, param: {}, disableLoader = false, upload?: boolean, blobOutput?: boolean): Observable<{}> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Pragma', 'no-cache');
    let responseType = 'json';
    this.blobOutput = blobOutput;
    let body = param;
    if (upload) {
      // Other header params are not accepted in upload (server)
      headers = new HttpHeaders({
        enctype: 'multipart/form-data',
      });
    } else {
      body = JSON.stringify(param);
    }

    if (!disableLoader) {
      this.showLoader(true);
    }
    if (this.blobOutput) {
      responseType = 'blob';
    }
    this.options = {
      headers,
      responseType,
      withCredentials: false,
    };

    return this.http.post(url, body, this.options).pipe(
      map((data: any) => {
        if (!disableLoader) {
          this.showLoader(false);
        }
        if (data.errorCode || data.errorMessage) {
        this.messagingService.notify('error', `Error: ${data.errorCode}`, data.errorMessage);
        return observableThrowError(data.errorCode);
        }
        return data;
      }),
      catchError((error) => {
        this.handleError(error, disableLoader);
        return observableThrowError(error);
      }) );
  }

  /**
   *
   * OAUTHLOGOUT method implementation in common rest service
   * @param url - get url
   * @param  param - url params
   * @param  [disableLoader] - boolean
   * @param  [blobOutput] - boolean
   * @param  [html] - enable/disable parse
   * @returns  - subscribe for reponse
   */
  public oauthLogout(url: string, param: {}, disableLoader = false, blobOutput?: boolean, html?: boolean): Observable<{}> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Pragma', 'no-cache');
    this.blobOutput = blobOutput;
    this.htmlOutput = html;
    let responseType;
    if (!disableLoader) {
      this.showLoader(true);
    }
    let queryParams = new HttpParams();
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        queryParams = queryParams.append(key, param[key]);
      }
    }
    if (this.blobOutput) {
      headers = headers.set('Accept', 'application/octet-stream');
      responseType = 'blob';
    } else {
      headers = headers.delete('Accept');
      responseType = 'json';
    }
    this.options = {
      headers,
      params: queryParams,
      responseType,
      withCredentials: true,
    };
    return this.http
      .get(url, this.options).pipe(
        map((data: Response) =>
          this.handleOauthResponse(data, disableLoader)),
        catchError((error) =>
         this.handleOauthLogoutError(error, disableLoader),
        ));
  }
  private handleOauthResponse(res: Response, disableLoader) {
    if (!disableLoader) {
      this.showLoader(false);
    }
    const body: Response = res;
    return body || {};
  }
  private handleOauthError = (error, disableLoader) => {
    if (!disableLoader) {
      this.showLoader(false);
    }
    if (error.error instanceof ErrorEvent) {
      console.log('Client-side error occured.');
      this.messagingService.notify('error', `Error: ${error.code}`, error.statusText);
    } else {
      console.log('Server-side error occured.');
      this.messagingService.notify('error', `Error: ${error.status}`, error.error.error_description);
    }

    return observableThrowError(error.error);
  }
  private handleOauthLogoutError = (error, disableLoader) => {
    if (!disableLoader) {
      this.showLoader(false);
    }
    if (error.error instanceof ErrorEvent) {
      console.log('Client-side error occured.');
    } else {
      console.log('Server-side error occured.');
    }

    return observableThrowError(error.error);
  }
  private handleResponse(res: Response, disableLoader) {
    if (!disableLoader) {
      this.showLoader(false);
    }
    const body: any = res;
    let message1;
    if (body.status && body.body.message) {
      message1 = body.body.message;
      
      if (body.status !== 200) {
        this.messagingService.notify('error', `Error: ${body.status}`, message1);
      } else
      if (body.status == 200) {
        this.messagingService.notify('success', 'Success', message1);
      }
    }
    if (this.blobOutput) {
      return res || {};
    }
    if (this.htmlOutput) {
      return  res || {};
    }
    return body || {};
  }
  private handleError = (error, disableLoader) => {
    if (!disableLoader) {
      this.showLoader(false);
    }
    if (error.status === 401 || error.status === 403) {
      if(error?.error?.message){
        this.messagingService.notify('error', `Error: ${error.status}`, `${error.error.message}`);
    }
      return this.forceLogout.next(true);
    }
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.messagingService.notify('error', `Error: 999`, errorMessage);
    } else {
      // server-side error
      if (error.status) {
        errorMessage = `Error Code: ${error.status}\nMessage:` + error?.error?.message ? `${error?.error?.message}` : `${error.message}`;
        this.messagingService.notify('error', `Error: ${error.status}`, error?.error?.message ? `${error?.error?.message}` : `${error.message}`);
      }
    }
    return observableThrowError(errorMessage);
  }
  public getText(url: string, param: {}, disableLoader = false): Observable<{}> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Pragma', 'no-cache');
    let responseType;
    if (!disableLoader) {
      this.showLoader(true);
    }
    let queryParams = new HttpParams();
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        queryParams = queryParams.append(key, param[key]);
      }
    }
    responseType = 'text';
    this.options = {
      headers,
      params: queryParams,
      responseType,
      withCredentials: true,
    };
    return this.http
      .get(url, this.options).pipe(
        map((data: Response) => this.handleOauthResponse(data, disableLoader)),
        catchError((error) => {
          this.handleError(error, disableLoader);
          return observableThrowError(error);
        }) );
  }
public showLoader(enable) {
  if (enable) {
    this.loaderService.show();
  } else {
    this.loaderService.hide();
  }
}
}
