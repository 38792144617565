import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(
    private titleService: Title,
    private metaService: Meta
  ) { }

  // Function to remove empty values inside an object
  public removeEmpty(obj: any): any {
    if (Array.isArray(obj)) {
      // Remove empty objects/values from array
      const filteredArray = obj
        .map(item => this.removeEmpty(item)) // Recursively clean objects inside arrays
        .filter(item => item !== undefined && item !== null && item !== '');
      return filteredArray.length ? filteredArray : undefined;
    } else if (typeof obj === 'object' && obj !== null) {
      // Remove empty keys and trim strings in objects
      const newObj = Object.keys(obj).reduce((acc, key) => {
        let value = obj[key];
        
        // Recursive clean for nested objects and arrays
        value = this.removeEmpty(value);
  
        // Trim string values
        if (typeof value === 'string') {
          value = value.trim();
        }
  
        if (value !== undefined && value !== null && value !== '') {
          acc[key] = value;
        }
  
        return acc;
      }, {} as any);
      
      return Object.keys(newObj).length ? newObj : undefined;
    } else if (typeof obj === 'string') {
      // Trim strings directly
      const trimmed = obj.trim();
      return trimmed ? trimmed : undefined;
    } else {
      return obj;
    }
  }

  // function to check if the object value exists and return '' instead of the value. to reduce code
  public ifExists(obj: any) {
    return obj ? obj : '';
  }

  // function to convert enum into array
  public getEnumArray(enumObj: Record<string, string>): { id: string; value: string; }[] {
    const enumArray = [];
    for (const [enumKey, enumValue] of Object.entries(enumObj)) {
      if (!Number.isNaN(Number(enumKey))) {
        continue;
      }
      enumArray.push({ id: enumKey, value: enumValue });
    }
    return enumArray;
  }



  /**
   * Method to update title and description for different component
   * for Google analytics purpose
   */
  public updateTitleAndDescription(title: string, description: string) {
    this.titleService.setTitle(title);
    this.metaService.updateTag(
      {
        name: 'description',
        content: description
      }
    );
  }
  
  public initIndex(pageNumber: number, pageSize: number) {
    return Number((pageSize * (pageNumber - 1))) ;
  }

  public checkValidAndDirty(control: AbstractControl| null| undefined) {

    return control && control.invalid && control.dirty ? true : false;
  }
  public getCurrentFinancialYear (){
    let today = new Date();
    if ((today.getMonth() + 1) <= 3) {
      return (today.getFullYear() - 1) + "-" + today.getFullYear().toString().substr(-2)
    } else {
      return today.getFullYear() + "-" + (today.getFullYear() + 1).toString().substr(-2)
    }
   }

  public convertString(status: string): string{
    return status.replace(/[&\/\\#, +()$~%.'":*?<>{}_]/g, ' ');
  }
}
