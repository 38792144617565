import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExternaluserredirectionComponent } from './externaluserredirection/externaluserredirection.component';
import { LoginComponent } from './login';
import { ChangepasswordComponent } from './user/changepassword/changepassword.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'changepassword', component: ChangepasswordComponent },
  { path: 'submit/abstract/external', component: ExternaluserredirectionComponent },
  { path: 'register', loadChildren: () => import('./register/register.module').then( (m) => m.RegisterModule) },
  { path: 'abstract', loadChildren: () => import('./user/abstract/abstract.module').then( (m) => m.AbstractSubmissionModule) },
]; 

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
