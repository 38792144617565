
 <app-message></app-message>
 <app-loader></app-loader>

<div class="abstract_wrap">
  <div class="abstract_grid">
      <!-- Sidebar  -->
      <div class="abstract_banner">
          <div class="wrap_banner_content">
              <div class="sidebar_head">
                  <h1>Abstract <br>Submission</h1>
              </div>
              <div class="sidebar_body">
                  <div class="brand_icon">
                      <img src="../assets/img/logo.png">
                      <img src="../assets/img/IASGCON 2024 logo.png">
                  </div>
                  
                  <!-- <div class="brand_icon">
                   
                  </div> -->
                  <div class="banner_title">
                    <h4>34TH ANNUAL CONFERENCE OF INDIAN ASSOCIATION OF SURGICAL GASTROENTEROLOGIST</h4>
                </div>
                  <div class="banner_info">
                      <div class="banner_icon_list">
                          <div class="list_icon">
                              <i class="ri-calendar-check-line"></i>
                          </div>
                          <h5>3rd October(Bursary) <br> 4th October (CME) <br>  5th & 6th October (Conference)</h5>
                      </div>
                      <div class="banner_icon_list">
                          <div class="list_icon">
                              <i class="ri-map-pin-line"></i>
                          </div>
                          <h5>
                            SAMRAT ASHOK CONVENTION CENTER - PATNA, BIHAR (INDIA)
                        </h5>
                      </div>
                  </div>
              </div>
              <div class="sidebar_foot">
                  <div class="powered_by">
                      <!-- <span>This event is managed by</span> -->
                      <img src="../assets/img/Association360-with-managedby-white.png">
                  </div>
              </div>
          </div>
      </div>
  <!-- Sidebar  -->

    
  <div class="abstract_content">
        <router-outlet></router-outlet>
    
        </div>

      </div>
      </div>