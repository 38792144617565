import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/services/auth.service';
import { CommunicationService } from 'src/services/communication.service';
import { UserService } from 'src/services/user.service';
import { validateAllFormFields } from 'src/utils/validateform';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss'],
})
export class ChangepasswordComponent implements OnInit , OnDestroy {
  @ViewChild('guideLineModal', { static: true })
  public guidlineModal: ModalDirective;
  loginedUserId;
  userName;
  status;
  loginedUserEmail;
  loginedUserFirstName;
  loginedUserMiddleName;
  loginedUserLastName;
  loginedUserTitle;
  public formGroup: FormGroup;
  constructor(
    public communicationService: CommunicationService,
    public router: Router,
    public authService: AuthService,
    public CookieService: CookieService,
    public route: ActivatedRoute,
    public userService: UserService,
    private formBuilder: FormBuilder
  ) {
    this.communicationService.getUserId.subscribe((userId) => {
      this.loginedUserId = userId;
    });
    this.communicationService.getUserName.subscribe((userId) => {
      this.userName = userId;
    });
  }
  ngOnInit(): void {
    this.getLoginedUserDetails(this.loginedUserId);
    this.formGroup = this.formBuilder.group({
      userName: new FormControl('', [
      ]),
      // oldPassword: new FormControl('', [
      //   Validators.required,
      //   Validators.minLength(8),
      //   Validators.maxLength(30)
      // ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(30)
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(30),
      ]),
    }, {
      validator: this.matchValidator,
    });
  }
  getLoginedUserDetails(loginedUserId) {

    this.userService.getUser(loginedUserId).subscribe((res) => {
      this.loginedUserEmail = res.response.list.entries["0"].entry.properties["cm:email"];
      this.loginedUserTitle = res.response.list.entries["0"].entry.properties["cm:title"];
      this.loginedUserFirstName =
        res.response.list.entries["0"].entry.properties["cm:firstName"];
      this.loginedUserMiddleName =
        res.response.list.entries["0"].entry.properties["cm:middleName"];
      this.loginedUserLastName =
        res.response.list.entries["0"].entry.properties["cm:lastName"];
    });
  }
  logoutConfirm() {
    // this.authService.logout().subscribe((res) => {
    //   // location.replace("https://isshp2023.mypage.acad360.com/logout");
    // });
      this.CookieService.deleteAll();
     this.CookieService.delete('access_token');
      sessionStorage.setItem('abstractData', '');
      sessionStorage.clear();
      this.communicationService.onlogoutUser();
      this.router.navigate(['/iasgcon2024']);
      sessionStorage.removeItem('abstractData');
    }
    openGuideLineModal() {
      this.guidlineModal.config = {
        animated: true,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: true,
      };
      this.guidlineModal.show();
    }
    ngOnDestroy() {
      if (this.guidlineModal) {
        this.guidlineModal.hide();
      }
    }
    onClickMysubmission() {
      this.router.navigate(['/iasgcon2024/abstract/mysubmissions'])
    }
    public onSubmit(values) {
      if (this.formGroup.valid) {
        this.userService.changeUserPassword(this.loginedUserEmail, values).subscribe((res) => {
  
          if (res.status.code === 0) {
            this.logoutConfirm();
            this.router.navigate(['/iasgcon2024']);
          }
        });
      } else {
        validateAllFormFields(this.formGroup);
  
      }
    }
    matchValidator(group: FormGroup) {
      const password: string = group.controls['password'].value; // get password from our password form control
      const confirmPassword: string = group.controls['confirmPassword'].value; // get password from our confirmPassword form control
      // compare is the password math
      if (password !== confirmPassword) {
        group.controls['confirmPassword'].setErrors({ PasswordMisMatch: true });
      }
    }
}
