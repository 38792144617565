<a href="javascript:void(0)" class="link_btn" (click)="openGuidelinesModal()" data-mdb-toggle="modal"
	data-mdb-target="#guidlineModal">View Guideline</a>


<!-- Guidline Modal -->
<div class="modal fade" bsModal #guidlineModal="bs-modal" id="guidlineModal" tabindex="-1"
	aria-labelledby="guidlineModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="guidlineModalLabel">Abstract Guidelines</h5>
				<button type="button" class="btn-close" (click)="guidlineModal.hide()" data-mdb-dismiss="modal"
					aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="static">
					<p>We request you to read these guidelines before submitting your abstract.&nbsp;</p>

					<ul>
						<li>If your abstract is accepted for presentation you must register for the conference.&nbsp;</li>
						<li>Those who have not registered will not be included in the final program and will not be able to present at the conference.</li>
						<li>Please note the registration fee details and the deadlines as given in the brochure. It is in your best interest to do an early bird registration to avail a discounted fee.</li>
						<li>Registration for the conference is not mandatory for submission of an abstract.</li>
						<li>If you have any queries, please contact the Scientific Secretariat at&nbsp;<a href="mailto:iasgscienticcommittee@gmail.com">iasgscienticcommittee@gmail.com</a>&nbsp;</li>
					</ul>
					
					<p><strong>Guidelines for submission of abstract(s)</strong></p>
					
					<ul>
						<li>Abstracts should be submitted online at <a href="https://iasg.abstracts.tvasan.in" target="_blank">https://iasg.abstracts.tvasan.in</a>. Abstracts submitted by email or any other mode will not be considered for selection.</li>
						<li>An abstract can be submitted to IASGCON 2025 only if it has not been published previously or has not been presented in more than one national meeting.&nbsp;</li>
						<li>The authors must choose a topic from the themes mentioned below.</li>
						<li>The authors must disclose their preferred mode of presentation during the online submission, i.e., oral or poster presentation.</li>
						<li>Grouping of data from the same work is recommended. Salami slicing is considered a form of scientific misconduct and may attract penalties as deemed appropriate by the IASG.</li>
						<li>The scientific method and result(s) or outcome(s) should be mentioned clearly in the abstract.</li>
						<li>Abstracts must contain data and have appropriate Institutional Ethical Committee clearance and meet current Ethical Standards.</li>
						<li>Conflict of interests: Authors and co-authors must declare any financial or any other interests in connection with the study.</li>
						<li>All submitted abstracts will be peer-reviewed. Based on the peer reviewers evaluation and the IASG scientific committee&rsquo;s assessment the abstracts will be considered for presentation and allocated to the appropriate category of presentation.&nbsp;</li>
						<li>All decisions regarding the theme and category will be made by the Scientific Communications Committee.</li>
						<li>Only one communication per presenting author is allowed.</li>
						<li>Please note the submission deadline below:</li>
					</ul>
					
					<p><strong>Submission Dates:</strong></p>
					
					<p>Last date without late fees: - 31 May, 2025</p>
					
					<p>Last date with late fees:&nbsp; -&nbsp;30 June,2025</p>
					
					<ul>
						<li>Under exceptional circumstances, if the presenting author is unable to present at the conference, they will be permitted to nominate one of the listed co-authors as the presenting author, who must be a registered delegate. The same should be communicated to the Secretary, IASG (<a href="mailto:iasgsecy@gmail.com">iasgsecy@gmail.com</a>), Organising Secretary, IASGCON and the IASG, Scientific Committee.</li>
					</ul>
					
					<ul>
						<li>Video abstracts (text) should be submitted along with the Video. The video file should be of a high quality and a maximum of only 8 minutes, in the usually readable compressed video formats (MP4, MPEG4)</li>
					</ul>
					
					<p><strong>IASGCON Publication Policy</strong></p>
					
					<ul>
						<li>Submitted abstracts which are submitted and presented will be published on the official IASG website</li>
						<li>Submitting an abstract allows IASGCON to record and replay your presentation (if accepted and presented).</li>
						<li>All abstracts&nbsp; accepted for presentation will also be published in a Supplement issue of Tropical Gastroenterology. Please note that you are responsible for proofing your abstracts prior to submission.&nbsp;</li>
						<li>All abstract titles and authors will be listed in the scientific programme.&nbsp;</li>
						<li>The presenting authors will be sent detailed guidelines including the timing of their presentation once their abstract is accepted. If you need to withdraw your abstract, a written statement reflecting the reasons for your withdrawal must be sent to <a href="mailto:iasgscienticcommittee@gmail.com">iasgscienticcommittee@gmail.com</a> no later than 31st <strong>August 2025.</strong></li>
					</ul>
					
					<p><strong>Tracks for Abstract submission</strong></p>
					
					<ol>
						<li>Esophagus</li>
						<li>Stomach</li>
						<li>Liver</li>
						<li>Biliary</li>
						<li>Pancreas</li>
						<li>Small Bowel</li>
						<li>Colo-rectal</li>
						<li>Transplantation</li>
						<li>Miscellaneous</li>
					</ol>
					
					<p><strong>Submission Categories</strong></p>
					
					<ul>
						<li>Free Paper (presentation as an oral paper or poster will be based on the assessment of the IASG)</li>
						<li>Video abstract</li>
					</ul>
					
					<p><strong>Abstract Structure</strong></p>
					
					<ul>
						<li>Abstracts must be submitted in English.</li>
						<li>The abstract should not be of more than 300 words.&nbsp;</li>
						<li>A table or figure may be included in the abstract.</li>
						<li>Abstracts should be structured using the following subheadings:</li>
					</ul>
					
					<ul>
						<li>For original research work: Background, Methods, Results, Conclusions.&nbsp;</li>
					</ul>
					
					<ul>
						<li>For case reports: No subheadings</li>
					</ul>
					
					<ul>
						<li>All abbreviations must be explained in full at the time of first use. Avoid abbreviations unless these improve readability of the text. Trade names and company names must not be mentioned in the title. However, trade names in brackets will be accepted in the body of the text if they are necessary to differentiate from similar products or to specify certain delivery devices or application modes.</li>
						<li>The authors&rsquo; names and affiliations should be provided only at the specified location and NOT within the text of the abstract.</li>
						<li>Please enter the details of all authors in the submission form in order of appearance in the abstract.</li>
						<li>If you have not completed all the required sections and details, your abstract will not be considered for review and will be rejected.</li>
						<li>If you have difficulty or questions regarding the submission process, please contact <a href="mailto:iasgscienticcommittee@gmail.com">iasgscienticcommittee@gmail.com</a>&nbsp;</li>
					</ul>
					
					<ul>
						<li>Once the author submits the abstract, all the co-authors will be notified by email.</li>
					</ul>
					
					<p>&nbsp;</p>
					
					<p>Help Desk for Technical Support is&nbsp;<a href="mailto:aruna@tvasan.in">aruna@tvasan.in</a>&nbsp;/&nbsp;+91&nbsp;96664&nbsp;55542</p>
					
					<p>&nbsp;</p>
					 
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn app_btn btn_close" (click)="guidlineModal.hide()"
					data-mdb-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>
<!--/. Guidline Modal -->