
<app-message></app-message>
<app-loader></app-loader>

<div class="abstract_wrap">
 <div class="abstract_grid">
     <!-- Sidebar  -->
     <div class="abstract_banner">
         <div class="wrap_banner_content">
             <div class="sidebar_head">
                 <h1>Abstract <br>Submission</h1>
             </div>
             <div class="sidebar_body">
                 <div class="brand_icon">
                     <img src="../assets/img/logo.png">
                     <img src="../assets/img/IASGCON2025-white.png">
                 </div>
                 
                 <!-- <div class="brand_icon">
                  
                 </div> -->
                 <div class="banner_title">
                   <h4>35th National Conference of
                    Indian Association of Surgical Gastro Enterology</h4>
               </div>
                 <div class="banner_info">
                     <div class="banner_icon_list">
                         <div class="list_icon">
                             <i class="ri-calendar-check-line"></i>
                         </div>
                         <h5> 09 – 12 October, 2025</h5>
                     </div>
                     <div class="banner_icon_list">
                         <div class="list_icon">
                             <i class="ri-map-pin-line"></i>
                         </div>
                         <h5>
                            ITC Grand Chola, Chennai
                       </h5>
                     </div>
                 </div>
             </div>
             <div class="sidebar_foot">
                 <div class="powered_by">
                     <!-- <span>This event is managed by</span> -->
                     <img src="../assets/img/Association360-with-managedby-white.png">
                 </div>
             </div>
         </div>
     </div>
 <!-- Sidebar  -->

   
 <div class="abstract_content">
       <router-outlet></router-outlet>
   
       </div>

     </div>
     </div>