<ul
  class="nav mb-3 flex-column {{ navColClass }}"
  [ngClass]="{
    'nav-pills': pills,
    'nav-tabs': !pills,
    'nav-fill': fill,
    'nav-justified': justified,
    'flex-column': vertical,
    'text-center': vertical
  }"
  role="tablist"
>
  <li
    *ngFor="let tab of tabs; let i = index"
    (click)="setActiveTab(i)"
    class="nav-item"
    role="presentation"
  >
    <a
      href="javascript:void(0)"
      class="nav-link"
      [class.active]="tab.active"
      [class.disabled]="tab.disabled"
      role="tab"
    >
      <ng-template [ngIf]="tab.titleContent">
        <ng-template [cdkPortalOutlet]="tab.titleContent"></ng-template>
      </ng-template>

      <ng-template [ngIf]="!tab.titleContent">{{ tab.title }}</ng-template>
    </a>
  </li>
</ul>

<div
  class="tab-content {{ contentColClass }}"
>
  <!-- <ng-content select="mdb-tab"></ng-content> -->
  <ng-container *ngFor="let tab of tabs">
    <div
      [@fadeIn]="animationState"
      (@fadeIn.done)="onAnimationDone()"
      class="tab-pane"
      [ngClass]="{
        show: tab.active,
        active: tab.active
      }"
    >
      <ng-template mdbTabPortalOutlet [tab]="tab"></ng-template>
    </div>
  </ng-container>
</div>
