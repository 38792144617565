export class AppConfig {
  public static BASE_URL = '/';
   public static BASE_URL_V1 = AppConfig.BASE_URL + 'api/v1';
   public static BASE_URL_V09 = AppConfig.BASE_URL + 'api/v0.9';
   public static GOOGLE_CLIENT_ID = '443377703313-6iv59osao10bastut01994lsgtnuioj1.apps.googleusercontent.com';
   public static SERVICE_URL = '/api';
   public static IFRAME_URL = window.location.origin;
   public static JOURNAL_ID ='iasg';
   public static DEFAULT_TENANT = 'iasg';
 }
