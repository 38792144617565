import { Compiler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../config/app.config';
import { RestService } from '../core/rest.service';
@Injectable()
export class AuthService {
  constructor(
    private restService: RestService, private compiler: Compiler,
    private router: Router) {
  }
  public userAlreadyExists(emailID) {
    return this.restService.get(`${AppConfig.BASE_URL_V1}/auth/${emailID}`, null).pipe(map((res: any) => res));
  }
    /**
 * 
 * @param loginData 
 * @returns 
 */
    public loginUsingEmail(loginData) {
      return this.restService
        .post(`${AppConfig.BASE_URL_V1}/auth/loginemail`, loginData, false).
        pipe(map((res: any) => res));
    }

      /**
 * 
 * @returns 
 */
  public logout() {
    return this.restService.post(`${AppConfig.BASE_URL_V1}/auth/logout`, undefined).pipe(map((res: any) => res));
  }

  sendotp(info) {
    return this.restService.post(`${AppConfig.BASE_URL_V1}/auth/sendotp`, info, null).pipe(map((res: any) => res));
  }
  loginotp(otpInfo) {
    return this.restService.post(`${AppConfig.BASE_URL_V1}/auth/loginotp`, otpInfo, null).pipe(map((res: any) => res));
  }


  public loginWithGoogle(emailId, password , register) {
    const data = {
      userId: emailId,
      password
    };
    return this.restService
      .post(AppConfig.BASE_URL + '/editaware/user/authorization/google?register=' + register, data , false).
      pipe(map((res: any) => res));
  }


  public forceLogout() {
    this.compiler.clearCache();
    localStorage.clear();
    this.router.navigate(['']);
  }
}
